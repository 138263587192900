/** @format */

export default [
  {
    header: 'Agent',
    role: ['other'],
  },
  {
    title: 'Home',
    icon: 'HomeIcon',
    route: 'vip_home',
    role: ['other'],
  },

  {
    title: 'My Information',
    icon: 'UserIcon',
    role: ['other'],

    children: [
      {
        title: 'My Information',
        route: 'vip_info',
      },
      {
        title: 'Downline Agent',
        route: 'vip_next',
      },
      {
        title: 'Team List',
        route: 'team_list',
      },
    ],
  },
  {
    title: 'Licensing & Appointment',
    tagVariant: 'success',
    icon: 'FlagIcon',
    route: 'vip_licensing_appointment',
    // children: [
    // {
    //   title: 'Training Materials',
    //   route: 'vip_tutorial-material',
    // },
    // {
    //   title: 'Training Videos',
    //   href: 'https://www.oneupus.com/T_T.O.P%E4%BC%97%E8%B6%8A%E8%B4%A2%E5%AF%8C/channels/65.html',
    // },
    // {
    //   title: 'Forms & Materials',
    //   route: 'vip_tutorial-library',
    // },
    // {
    //   title: 'Training & Tutorial',
    //   route: 'vip_tutorial-library-cate',
    // },
    //  {
    //   title: 'Licensing & Appointment',
    //  route: 'vip_licensing_appointment',
    //  },
    // ],
  },
  {
    title: 'Submit my policy',
    role: ['other'],
    icon: 'CheckSquareIcon',
    route: 'vip_insurance-policy-submit',
  },
  {
    title: 'My policy',
    icon: 'FileTextIcon',
    role: ['other'],
    children: [
      {
        title: 'Policy List',
        route: 'vip_insurance-policy',
        icon: 'AwardIcon',
      },
      {
        title: 'Earnings Record',
        route: 'Earnings_record',
      },

      // {
      //   title: 'Team Awards',
      //   route: 'team_reward',
      // },
      // {
      //   title: '保单记录',
      //   route: 'vip_Commission_record',
      // },
      // {
      //   title: '支付记录',
      //   route: 'vip_Pay_record',
      // },
      {
        title: 'Commission Report',
        route: 'vip_Commission_record',
      },
      {
        title: 'TP Report',
        route: 'vip_analytics',
        tagVariant: 'warning',
      },
      // {
      //  title: 'Earning Records',
      //   route: 'vip_earning_records',
      //   tagVariant: 'warning',
      // },
    ],
  },
  {
    title: 'Resources',
    icon: 'AirplayIcon',
    role: ['other'],
    children: [
      {
        title: 'Forms & Materials',
        route: 'vip_tutorial-library',
      },
      {
        title: 'Brochures',
        route: 'vip_tutorial-library-brochures',
      },
      {
        title: 'Training & Tutorial',
        route: 'vip_tutorial-library-training&tutorial',
      },
      {
        title: 'Training Videos',
        href: 'https://www.oneupus.com/T_T.O.P%E4%BC%97%E8%B6%8A%E8%B4%A2%E5%AF%8C/channels/65.html',
      },
      {
        title: 'Compliance Manual',
        route: 'vip_tutorial-library-compliance',
      },
    ],
  },
  {
    title: 'Compensation Plan',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Commission Schedules',
        route: 'level_detail',
      },
      {
        title: 'Renewal & Excess Grid',
        route: 'level_detail_two',
      },
    ],
  },
  {
    title: 'Scoreboards',
    icon: 'BarChart2Icon',
    // route: 'vip_score-report',
    // children: [
    //   {
    //     title: 'Commission Report',
    //     route: 'vip_commission-report',
    //   },
    //   {
    //     title: 'Scoreboards',
    //     route: 'vip_score-report',
    //   },
    // ],
    children: [
      {
        title: 'Inforce TP',
        route: 'vip_inforce_TP',
        // href: 'https://api.myonewow.com/ziomyun/tprank.php',
      },
      {
        title: 'Pending TP',
        route: 'vip_pending_TP',
        // href: 'https://api.myonewow.com/ziomyun/ptprank.php',
      },
      {
        title: 'Recruiting',
        route: 'vip_recruiting',
        // href: 'https://api.myonewow.com/ziomyun/recruiting.php',
      },
    ],
  },
  {
    title: 'News',
    role: ['other'],
    icon: 'GlobeIcon',
    href: 'https://www.oneupus.com/the-one-zone/',
  },
  {
    title: 'Chat',
    icon: 'MessageSquareIcon',
    route: 'vip_message-list',
  },
  // {
  // title: 'Calendar',
  // icon: 'CalendarIcon',
  // route: 'calendar',
  // },
]
